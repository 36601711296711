import { assign, fromPromise, setup } from 'xstate';
import { Download, SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../api';
import { ChecksumMismatchError, InternalError } from './downloadErrors';
import { DownloadMode, DownloadTarget } from './types';

type MachineContext = {
  mode: DownloadMode;
  component: Tool | SoftwareApp;
  version: ToolVersion | SoftwareAppVersion;
  projectId?: number;
  error?: Error;
  target?: DownloadTarget | undefined;
  downloadUrl?: string;
  receivedChecksum?: string;
};

type DownloadMachineEvent = { type: 'TARGET_SELECTED'; target: DownloadTarget } | { type: 'ERROR'; error: Error } | { type: 'RETRY' };

type DownloadMachineHandler = {
  createDownloadUrl: (ctx: MachineContext) => Promise<Download>;
};

export const machine = (initial: MachineContext, handler: DownloadMachineHandler) =>
  setup({
    types: {
      context: {} as MachineContext,
      events: {} as DownloadMachineEvent
    },
    actors: {
      createDownloadUrl: fromPromise<Download, { context: MachineContext }>(({ input }) => handler.createDownloadUrl(input.context))
    }
  }).createMachine({
    context: initial,
    initial: 'initial',
    states: {
      initial: {
        always: [
          {
            target: 'selectingTarget',
            guard: (ctx) => !ctx.context.target && (ctx.context.version as SoftwareAppVersion)?.targets?.length > 1
          },
          { target: 'creatingDownloadUrl', guard: (ctx) => !!ctx.context.target && ctx.context.mode !== 'undefined' },
          { target: 'error', actions: assign({ error: new InternalError() }) }
        ]
      },
      selectingTarget: {
        on: {
          TARGET_SELECTED: {
            target: 'creatingDownloadUrl',
            actions: assign({
              target: (ctx) => ctx.event.target
            })
          }
        }
      },
      creatingDownloadUrl: {
        invoke: {
          src: 'createDownloadUrl',
          input: (args) => ({ context: args.context }),
          onDone: [
            {
              // only download if the received checksum matches the expected one
              target: 'autoDownload',
              guard: (args) => args.event.output.pactsSha256 === args.context.target?.sha256,
              actions: assign({
                downloadUrl: (ctx) => ctx.event.output.url,
                receivedChecksum: (ctx) => ctx.event.output.pactsSha256
              })
            },
            {
              // if the received checksum does not match the requested one, prevent download
              // this happens e.g., if a deployment plan contains an old sha256 but the
              // target file has changed in the meantime
              target: 'error',
              guard: (args) => args.event.output.pactsSha256 !== args.context.target?.sha256,
              actions: assign({
                error: new ChecksumMismatchError()
              })
            }
          ],
          onError: {
            target: 'error',
            actions: assign({
              error: (ctx) => ctx.event.error as Error
            })
          }
        }
      },
      autoDownload: {
        on: {
          RETRY: {
            target: 'initial',
            actions: assign({
              downloadUrl: () => undefined
            })
          }
        }
      },
      error: {
        on: {
          RETRY: {
            target: 'initial',
            actions: assign({
              downloadUrl: () => undefined
            })
          }
        }
      }
    }
  });
