import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Skeleton, Space, TableColumnType, Typography } from 'antd';
import { StyledModal, useTableFilter, useTableSearch } from '../../../contexts/shared/components';
import Table from '../../../contexts/shared/components/Table/Table';
import { Project, ProjectResource, ResourceError } from '../../../api';
import styled from 'styled-components';
import { useAllProjectsResources } from '../hooks/useProjectsResources';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import ProjectDetails from './ProjectDetails';
import { useProjects } from '../../../contexts/projects/hooks';
import { ImportProject } from './ImportProject';

export interface DataType {
  key: React.Key;
  name: string;
  status: string;
}

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export const AllProjectResources = () => {
  const projectResources = useAllProjectsResources();
  const projects = useProjects();
  const isLoading = useDebounce(projectResources.isLoading || projects.isLoading || projectResources.isFetchingNextPage, 10, true);

  const statuses: string[] = [...uniq(projectResources.data?.map((project) => project?.infrastructureStatus) || []), 'uninitialized'];
  const [errorModal, setErrorModal] = useState<null | ResourceError>(null);

  const merged = useMemo(() => {
    const ret: { [key: number]: { project?: Project; pf?: ProjectResource } } = {};

    projects.data?.forEach((p) => (ret[p.idProject] = { ...ret[p.idProject], project: p }));
    projectResources.data?.forEach((pr) => (ret[parseInt(pr.id, 10)] = { ...ret[parseInt(pr.id, 10)], pf: pr }));

    return ret;
  }, [projects.data, projectResources.data]);

  const columns: TableColumnType<(typeof merged)[number]>[] = [
    {
      title: 'Name',
      render: (_, record) => record.project?.name ?? record.pf?.name,
      key: 'name',
      ...useTableSearch({
        searchParamId: 'name',
        searchValueProvider: (record) => record.project?.name ?? record.pf?.name ?? ''
      })
    },
    {
      title: 'Status',
      render: (_, record) => {
        if (!record.pf) return <ImportProject project={record.project!} />;
        return record.pf?.infrastructureStatus;
      },
      key: 'status',
      ...useTableFilter({
        searchParamId: 'status',
        values: statuses.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const typed = data as (typeof merged)[number] | undefined;
          if (!typed?.pf && filterVal === 'uninitialized') return true;
          return typed?.pf?.infrastructureStatus === filterVal;
        },
        key: statuses.join('+')
      })
    }
  ];

  return (
    <FullWidthSpace direction="vertical">
      <StyledModal title={errorModal?.summary} open={errorModal != null} destroyOnClose onCancel={() => setErrorModal(null)} onOk={() => setErrorModal(null)}>
        <Typography.Paragraph>Error Code: {errorModal?.errorCode}</Typography.Paragraph>
        <Typography.Paragraph>Internal Error code: {errorModal?.technicalDetails?.internalErrorCode}</Typography.Paragraph>
        <Typography.Paragraph>Summary: {errorModal?.technicalDetails?.description}</Typography.Paragraph>
      </StyledModal>
      <Skeleton loading={isLoading} active>
        <Table
          columns={columns}
          rowKey={(e) => e.project?.idProject ?? e.pf?.id ?? ''}
          dataSource={Object.values(merged)}
          expandable={{
            rowExpandable: (project) => (project as unknown as ProjectResource).infrastructureStatus !== 'DELETED',
            expandedRowRender: (project) => project.pf && <ProjectDetails onSetErrorModal={setErrorModal} project={project.pf} />
          }}
        />
      </Skeleton>
    </FullWidthSpace>
  );
};
