import { Result, Skeleton, List, Space, Card, Row, Col } from 'antd';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 } from 'uuid';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useFilteredNotifications } from '../hooks/useNotifications';
import { ArchiveNotification } from './ArchiveNotification';
import { NotificationDetails } from './NotificationDetails';
import { Notification } from './Notification';
import { NotificationMatcher } from '../domain/extractor';

export const NotificationsList = (props: {
  filter?: RegExp;
  height?: string;
  clearDisabled?: boolean;
  listStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  matcher: NotificationMatcher[];
  linkHandler?: (href: string) => { preventDefault: boolean };
}) => {
  const notifications = useFilteredNotifications(props.filter);
  const permissions = usePermissions();
  const divId = useRef(`scrollable-${v4()}`);
  const hasNextPage = notifications.hasNextPage !== undefined ? notifications.hasNextPage : notifications.isLoading;
  const canLoadNotifications = permissions.notificationSvc$userNotificationsRequest;
  return (
    <div
      id={divId.current}
      style={{
        height: `calc(${props.height || '100%'} - 90px)`,
        overflow: 'auto'
      }}
    >
      {!canLoadNotifications && <Result title="Missing Permissions" subTitle="You are not entitled to use this feature." />}
      <InfiniteScroll
        dataLength={notifications.data?.length || 0}
        next={() => notifications.fetchNextPage()}
        hasMore={hasNextPage && canLoadNotifications}
        loader={<Skeleton style={{ padding: 16 }} paragraph={{ rows: 2 }} active />}
        scrollableTarget={divId.current}
      >
        <List
          itemLayout="vertical"
          dataSource={notifications.data}
          bordered={false}
          style={{ ...props.listStyle }}
          split={false}
          locale={{
            emptyText: notifications.isSuccess && !hasNextPage ? <Space>No Notifications</Space> : <></>
          }}
          renderItem={(item) => {
            return (
              <List.Item key={item.id} style={{ padding: 6, ...props.itemStyle }}>
                <Card bodyStyle={{ padding: 16 }}>
                  <Row wrap={false}>
                    <Col flex="auto">
                      <Notification notification={item} matcher={props.matcher} linkHandler={props.linkHandler} />
                    </Col>
                    <div style={{ position: 'absolute', top: -4, left: -4 }}>
                      {permissions.all$unrestrictedAdministration && <NotificationDetails notification={item} />}
                    </div>
                    {!props.clearDisabled && (
                      <Col flex="16px">
                        <ArchiveNotification key="archive" notification={item} />
                      </Col>
                    )}
                  </Row>
                </Card>
              </List.Item>
            );
          }}
        />
      </InfiniteScroll>
    </div>
  );
};
