import { BellFilled, BellOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Row, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { CustomEvents } from '../../../domain';
import { Circle } from '../../shared/components/icons/Circle';
import { useNotifications } from '../hooks/useNotifications';
import { useStyles } from '../../theme/useStyles';
import styled from 'styled-components';
import { THEMES } from '../../theme/ThemeProvider';

const IconWrapperCol = styled(Col)<{ fontSize: number; paddingTop?: number; actualTheme: THEMES }>`
  font-size: ${({ fontSize }) => fontSize}px;
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : undefined)};
  color: ${({ theme, actualTheme }) => (actualTheme === THEMES.DARK ? theme.colorWhite : undefined)};
`;

const MenuButton = styled(Button)`
  color: ${({ theme }) => theme.colorText};
  padding: 0 4px;
`;

export const NotificationMenuButtonContent = (props: {
  iconStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  onClick: () => any;
  withTooltip?: boolean;
}) => {
  const notifications = useNotifications();
  const ids = notifications.data?.pages.map((p) => p.content.map((c) => c.id)).flat() || [];
  const count = ids.length;
  const [animation, setAnimation] = useState(true);
  const styles = useStyles();

  useEffect(() => {
    const onEvent = () => {
      setAnimation(false);
      setImmediate(() => {
        setAnimation(true);
      });
    };

    window.addEventListener(CustomEvents.NOTIFICATION_RECEIVED, onEvent);

    return () => {
      window.removeEventListener(CustomEvents.NOTIFICATION_RECEIVED, onEvent);
    };
  }, []);
  const icon = count > 0 ? <BellFilled className={animation ? 'ring' : ''} /> : <BellOutlined />;

  const button = (
    <Badge offset={[-4, 4]} count={count > 0 ? <Circle size={8} style={{ color: styles.colorError }} /> : 0} size="default">
      <MenuButton onClick={props.onClick} type="text">
        <Row wrap={false} gutter={4} align="middle">
          <IconWrapperCol fontSize={18} actualTheme={THEMES.DARK}>
            {icon}
          </IconWrapperCol>
        </Row>
      </MenuButton>
    </Badge>
  );

  const assembly = props.withTooltip ? (
    <Tooltip title="My Notifications" placement="left" trigger={['hover']} getPopupContainer={(trigger) => trigger}>
      {button}
    </Tooltip>
  ) : (
    button
  );

  return assembly;
};
