import { Col, Popover, Row, Typography } from 'antd';
import { notificationMatchers } from '../domain';
import { useNotifications } from '../hooks/useNotifications';
import { ArchiveAllNotifications } from './ArchiveAllNotifications';
import { NotificationMenuButtonContent } from './NotificationMenuButtonContent';
import { NotificationsList } from './NotificationsList';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { useEffect, useState } from 'react';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const NotificationMenuButton = () => {
  const notifications = useNotifications();
  const [open, setOpen] = useState(false);
  const permissions = usePermissions();
  const canLoadNotifications = permissions.notificationSvc$userNotificationsRequest;

  const [initiallyOpen, setInitiallyOpen] = useSearchParameter('showNotifications');

  // if initially open reset search param and open notification list
  // to prevent opening the notification list on subsequent load
  useEffect(() => {
    if (initiallyOpen) {
      setOpen(true);
      setInitiallyOpen('');
    }
  }, [initiallyOpen, setInitiallyOpen, setOpen]);

  if (!canLoadNotifications) return null;

  const ids = notifications.data?.pages.map((p) => p.content.map((c) => c.id)).flat() || [];

  const title = (
    <Row align="middle">
      <Col flex="auto">
        <Typography.Text strong>Notifications</Typography.Text>
      </Col>
      <Col flex="16px">
        <ArchiveAllNotifications key="archiveAll" ids={ids} />
      </Col>
    </Row>
  );
  const content = (
    <div style={{ width: 480, maxWidth: '90vw' }}>
      <MetricOnVisible view={MetricViewIds.notificationList} />
      <NotificationsList height="66vh" matcher={notificationMatchers} />
    </div>
  );
  return (
    <Popover
      title={title}
      content={content}
      trigger="click"
      getPopupContainer={(trigger) => trigger}
      placement="bottomRight"
      open={open}
      onOpenChange={setOpen}
    >
      <NotificationMenuButtonContent
        onClick={() => {
          /* */
        }}
        withTooltip
      />
    </Popover>
  );
};
