import { Skeleton, Descriptions, Card } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { usePermissions } from '../../session/hooks/usePermissions';
import { formatDateTime } from '../../shared/components/formatDate';
import { useEngineeringTool } from '../hooks/useEngineeringTool';
import { useDeleteEngineeringTool } from '../hooks/useDeleteEngineeringTool';
import { UpdateEngineeringTool } from './UpdateEngineeringTool';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { MarkdownRenderer } from '../../shared/components/MarkdownRenderer';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { Tool } from '../../../api/engineering/domain/types';
import { BackToProjectOrEngineeringPlatformButton } from '../../navigation/components/BackToProjectOrEngineeringPlatformButton';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { useProject } from '../../../contexts/projects/hooks/useProject';

export const EngineeringToolDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [tool, setTool] = useState({} as Tool);
  const fetchTool = useEngineeringTool(id!);
  const permissions = usePermissions();
  const navigate = useInAppNavigate();
  const [searchParam] = useSearchParams();
  const projectId = searchParam.get('projectId') || '';
  const deleteEngineeringTool = useDeleteEngineeringTool();
  const project = useProject(projectId);

  useEffect(() => {
    if (fetchTool.isSuccess) {
      setTool(fetchTool.data);
      setLoading(false);
    }
  }, [fetchTool.data, fetchTool.isSuccess]);

  return (
    <>
      <CenteredLayout
        title={loading ? '' : `Details:  ${tool.name}`}
        fixed
        extra={[
          <UpdateEngineeringTool key="update" tool={tool} detailView />,
          permissions.engineeringSvc$deleteTool && (
            <ConfirmationButton
              danger
              buttonType='regular'
              regularButtonType='default'
              key="delete"
              onOk={() => {
                deleteEngineeringTool
                  .mutateAsync([tool])
                  .then(() => (projectId ? navigate(`/projects/${projectId}/tools`, { replace: true }) : navigate('/tools', { replace: true })));
              }}
            >
              Delete
            </ConfirmationButton>
          ),
          <BackToProjectOrEngineeringPlatformButton key="back" project={project?.data ?? null} isLoading={Boolean(project.isLoading && projectId)} />
        ]}
      >
        <Skeleton loading={loading}>
          <Card bodyStyle={{ padding: '0px' }}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Description">
                <MarkdownRenderer centered>{`${tool.description}`}</MarkdownRenderer>
              </Descriptions.Item>
              <Descriptions.Item label="Documentation">
                <MarkdownRenderer centered>{`${tool.documentationLink}`}</MarkdownRenderer>
              </Descriptions.Item>
              <Descriptions.Item label="Created">
                {tool.createdAt ? formatDateTime(new Date(tool.createdAt)) : '-'}
                {tool.createdBy ? ` by ${tool.createdBy}` : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Updated">
                {tool.updatedAt ? formatDateTime(new Date(tool.updatedAt)) : '-'}
                {tool.updatedBy ? ` by ${tool.updatedBy}` : ''}
              </Descriptions.Item>
              <Descriptions.Item
                label={tool.category?.length === 1 ? 'Category' : 'Categories'}
                contentStyle={{ alignContent: 'center', justifyContent: 'center' }}
              >
                {tool.category?.map((cat) => cat.name).join('; ')}
              </Descriptions.Item>
              {permissions.all$unrestrictedAdministration && <Descriptions.Item label="Tool Id">{`${tool.id}`}</Descriptions.Item>}
            </Descriptions>
          </Card>
        </Skeleton>
      </CenteredLayout>
    </>
  );
};
