import * as antd from 'antd';
import { Rule } from 'antd/es/form';
import { FileSelection, UploadAnt } from './AntBlobUpload';
import { useState } from 'react';

export const BlobUploadFormItem = (props: {
  label: string;
  field: (string | number)[];
  relativeFieldInList?: (string | number)[];
  labelAlign?: 'left' | 'right';
  rules?: Rule[];
  optional?: boolean;
  uploadDisabled?: boolean;
  shouldUpdate: (prevValuse: any, currentValues: any) => boolean;
}) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [lastEmitted, setLastEmitted] = useState<FileSelection | undefined>();
  return (
    <antd.Form.Item
      label={props.label}
      labelAlign={props.labelAlign}
      required={!props.optional}
      shouldUpdate={(prev, next) => {
        if (forceRefresh) {
          setForceRefresh(false);
          return true;
        }
        return props.shouldUpdate(prev, next);
      }}
      style={{ marginTop: 16 }}
    >
      {({ getFieldValue, setFieldValue, getFieldError, validateFields }) => {
        const validateAndForceRefresh = () => {
          validateFields([props.field])
            .catch(() => {
              /* */
            })
            .finally(() => {
              setForceRefresh(true);
            });
        };

        const rules: Rule[] = [
          ...(props.rules || []),
          {
            validator: () => {
              // Here, the required rule should kick in
              if (!lastEmitted?.link) return Promise.resolve();

              // Reject if invalid extension
              const extension = lastEmitted?.file?.extension || lastEmitted?.link.split('.').pop() || '';
              if (!extension) return Promise.reject(new Error('Invalid file extension.'));

              return Promise.resolve();
            }
          }
        ];

        return (
          <>
            <UploadAnt
              initialValue={getFieldValue(props.field)}
              uploadDisabled={props.uploadDisabled}
              onChange={(v) => {
                setLastEmitted(v);
                setFieldValue(props.field, v.link);
                // delay validation by one render cycle to make sure
                // the prior state changes have been propagated
                setImmediate(() => {
                  validateAndForceRefresh();
                });
              }}
              showError={getFieldError(props.field).length > 0}
            />
            <antd.Form.Item noStyle name={props.relativeFieldInList || props.field} rules={rules} />
          </>
        );
      }}
    </antd.Form.Item>
  );
};
