import { Badge, Card, Descriptions, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { BackToProjectOrSoftwarePlatformButton } from '../../navigation/components/BackToProjectOrSoftwarePlatformButton';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { useSearchParameters } from '../../navigation/hooks/useSearchParameters';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { formatDateTime } from '../../shared/components/formatDate';
import { MarkdownRenderer } from '../../shared/components/MarkdownRenderer';
import { SoftwareAppScope } from '../hooks/scopedSoftwareApp';
import { useDeleteCommonSoftwareApp } from '../hooks/useDeleteCommonSoftwareApp';
import { useDeleteProjectSoftwareApp } from '../hooks/useDeleteProjectSoftwareApp';
import { useSoftwareApp } from '../hooks/useSoftwareApp';
import { UpdateCommonSoftwareApp } from './UpdateCommonSoftwareApp';
import { UpdateProjectSoftwareApp } from './UpdateProjectSoftwareApp';
import { useProject } from '../../../contexts/projects/hooks/useProject';

export const SoftwareAppDetails = () => {
  const params = useParams();
  const searchParam = useSearchParameters();
  const id = params.id || '';
  const projectId = params.projectid || searchParam.projectId || '';
  const appScope = searchParam.scope as SoftwareAppScope | undefined;
  const deleteCommonApp = useDeleteCommonSoftwareApp();
  const deleteProjectApp = useDeleteProjectSoftwareApp();
  const fetchId = appScope === 'common' ? '' : projectId;
  const appFetch = useSoftwareApp(id, fetchId);
  const permissions = usePermissions({ projectId });
  const navigate = useInAppNavigate();
  const project = useProject(projectId);

  const app = appFetch.data;
  const projectApp = appScope === 'project';

  return (
    <>
      <CenteredLayout
        fixed
        title={app ? `Details:  ${app?.name || ''}` : ''}
        extra={[
          projectApp
            ? app && <UpdateProjectSoftwareApp softwareApp={app} projectId={projectId} detailView />
            : app && <UpdateCommonSoftwareApp softwareApp={app} detailView />,
          (projectApp ? permissions.engineeringSvc$deleteProjectSoftwareApp : permissions.engineeringSvc$deleteCommonSoftwareApp) && app && (
            <ConfirmationButton
              danger
              buttonType='regular'
              regularButtonType='default'
              onOk={() => {
                if (projectApp) {
                  deleteProjectApp.mutateAsync([`${projectId}`, `${app.idSoftwareApp}`]).then(() => navigate(`/projects/${projectId}/apps`, { replace: true }));
                } else {
                  deleteCommonApp
                    .mutateAsync([`${app.idSoftwareApp}`])
                    .then(() => (projectId ? navigate(`/projects/${projectId}/apps`, { replace: true }) : navigate('/apps', { replace: true })));
                }
              }}
            >
              Delete
            </ConfirmationButton>
          ),
          <BackToProjectOrSoftwarePlatformButton key="back" project={project.data ?? null} isLoading={project.isLoading && projectId} />
        ]}
      >
        <Skeleton loading={!app}>
          <Card bodyStyle={{ padding: '0px' }}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Description">
                <MarkdownRenderer centered>{`${app?.description}`}</MarkdownRenderer>
              </Descriptions.Item>
              <Descriptions.Item label="Documentation">
                <MarkdownRenderer centered>{`${app?.documentationLink}`}</MarkdownRenderer>
              </Descriptions.Item>
              <Descriptions.Item label="Created">
                {app?.createdAt ? formatDateTime(new Date(app?.createdAt)) : '-'}
                {app?.createdBy ? ` by ${app?.createdBy}` : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Updated">
                {app?.updatedAt ? formatDateTime(new Date(app?.updatedAt)) : '-'}
                {app?.updatedBy ? ` by ${app?.updatedBy}` : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Category">{app?.categories?.map((cat) => cat.name).join('; ')}</Descriptions.Item>
              <Descriptions.Item label={app?.latestVersion?.targets?.length === 1 ? 'Target' : 'Targets'}>
                {app?.latestVersion?.targets?.map((cat) => cat.target.name).join('; ')}
              </Descriptions.Item>
              <Descriptions.Item label="Application type">
                {appScope === 'project' ? (
                  <Badge status="warning" text="Project specific" />
                ) : (
                  <Badge status="error" text="Common / Software Platform Application" />
                )}
              </Descriptions.Item>
              {permissions.all$unrestrictedAdministration && <Descriptions.Item label="Application Id">{`${app?.idSoftwareApp}`}</Descriptions.Item>}
              {projectId && permissions.all$unrestrictedAdministration && <Descriptions.Item label="Project Id">{`${projectId}`}</Descriptions.Item>}
            </Descriptions>
          </Card>
        </Skeleton>
      </CenteredLayout>
    </>
  );
};
