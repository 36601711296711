import { Form, FormInstance, Input, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { DescriptionValidator } from '../../../domain/validation/descriptionValidator';
import { DocumentationLinkValidator } from '../../../domain/validation/documentationLinkValidator';
import { NameValidator } from '../../../domain/validation/nameValidator';
import { ReleaseNotesValidator } from '../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../domain/validation/sha256Validator';
import { useSoftwareAppCategories } from '../hooks/useSoftwareAppsCategories';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../constants/texts';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { useSoftwareappTargets } from '../hooks/useSoftwareappTargets';
import { BlobUploadFormItem } from '../../shared/components/BlobUploadFormItem';
import { ChecksumHowToFormLabel } from '../../../contexts/shared/components/ChecksumHowToFormLabel';
import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../shared/components/Forms/ICSPortalComponentIdFormItem';

import { SoftwareApp, SoftwareAppCreate, TargetWithDownloadLink } from '../../../api/engineering/domain/types';

const { Option } = Select;

export const AddSoftwareAppForm = (props: {
  initial: SoftwareAppCreate;
  onFinish: (data: SoftwareAppCreate) => Promise<void>;
  ok: (ok: () => any) => any;
  reset: (cancel: () => any) => any;
  form?: FormInstance;
}) => {
  const categories = useSoftwareAppCategories();
  const targets = useSoftwareappTargets();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };
  const [form] = Form.useForm<SoftwareApp>(props.form);

  props.ok(form.submit);
  props.reset(form.resetFields);

  const updateTargets = (current: number[], data: TargetWithDownloadLink[]) => {
    const ret = current.map((tId) => {
      const associatedTarget = targets.data?.find((tar) => tar.idTarget === tId);
      if (!_.isArray(data)) {
        data = [data];
      }
      const link = [...data]?.find((t) => t?.target?.idTarget === associatedTarget?.idTarget)?.downloadLink;
      const retTarget = {
        target: associatedTarget,
        downloadLink: link || ''
      } as TargetWithDownloadLink;
      return retTarget;
    });
    return ret;
  };

  return (
    <Form<SoftwareApp>
      form={form}
      {...layout}
      preserve
      name="basic"
      onFinish={(value) => {
        const copy = { ...value } as SoftwareAppCreate;
        if (copy && copy.categories) {
          const id = copy.categories[0].id;
          const cat = categories.data?.find((c) => c.id === id);
          const cats = cat ? [cat] : [];
          copy.categories = cats;
        }
        copy.latestVersion = Object.assign(props.initial.latestVersion, copy.latestVersion);
        props.onFinish(Object.assign(props.initial, copy));
      }}
      initialValues={props.initial}
    >
      <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        rules={[DescriptionValidator.rule()]}
        label="Description"
        field={['description']}
        shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
      />
      <MarkdownFormItem
        optional
        rules={[DocumentationLinkValidator.rule()]}
        label="Documentation"
        field={['documentationLink']}
        shouldUpdate={(prevValues, currentValues) => prevValues.documentationLink !== currentValues.documentationLink}
      />
      <Form.Item label="Version String" name={['latestVersion', 'version']} rules={[VersionValidator.rule()]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        rules={[ReleaseNotesValidator.rule()]}
        label="Release Notes"
        field={['latestVersion', 'releaseNotes']}
        placeholder={RELEASE_NOTES_PLACEHOLDER}
        shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
      />
      <Form.Item
        label="Targets"
        shouldUpdate={(p, n) => p.latestVersion.targets !== n.latestVersion.targets}
        rules={[
          {
            required: true,
            message: 'Required',
            validator: (rule, value) => {
              if (value?.length > 0 || form.getFieldValue(['latestVersion', 'targets']).length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Argument missing'));
            }
          }
        ]}
      >
        {() => {
          return (
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              value={
                form.getFieldValue(['latestVersion', 'targets'])?.length > 0
                  ? form.getFieldValue(['latestVersion', 'targets']).map((t: TargetWithDownloadLink) => t.target.idTarget)
                  : []
              }
              placeholder="Please select"
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
              onChange={(val: number[]) => {
                const fields = { ...form.getFieldsValue() } as SoftwareAppCreate;
                if (fields.latestVersion) {
                  fields.latestVersion.targets = updateTargets(val, form.getFieldValue(['latestVersion', 'targets']));
                }
                form.setFieldsValue(fields);
              }}
            >
              {targets.data?.map((target) => {
                return (
                  <Option key={target.idTarget || -1} value={target.idTarget || -1} title={target.name}>
                    {target.name}
                  </Option>
                );
              })}
            </Select>
          );
        }}
      </Form.Item>
      <Form.List name={['latestVersion', 'targets']}>
        {(fields) => {
          return (
            <div>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <BlobUploadFormItem
                    label={`Download Link ${form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])}`}
                    field={['latestVersion', 'targets', field.key, 'downloadLink']}
                    relativeFieldInList={[field.key, 'downloadLink']}
                    rules={[{ required: true, message: 'Required' }]}
                    key={form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])}
                    shouldUpdate={(prevValues, currentValues) => {
                      return (
                        JSON.stringify(prevValues.latestVersion.targets) !== JSON.stringify(currentValues.latestVersion.targets)
                      );
                    }}
                  />
                  <Form.Item
                    label={<ChecksumHowToFormLabel target={form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])} />}
                    name={[field.key, 'sha256']}
                    required={false}
                    rules={[Sha256Validator.rule(() => form.getFieldValue(['latestVersion', 'targets', index, 'downloadLink']))]}
                  >
                    <Input />
                  </Form.Item>

                  <ICSPortalComponentIdFormItem
                    target={form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])}
                    name={[field.key, ICS_PORTAL_ID_NAME_KEY]}
                  />
                </React.Fragment>
              ))}
            </div>
          );
        }}
      </Form.List>
      <Form.Item
        label="Category"
        name={['categories', 0, 'id']}
        rules={[
          {
            required: true,
            message: 'Required',
            validator: (rule, value) => {
              if (value > -1 || form.getFieldValue('categories').length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Argument missing'));
            }
          }
        ]}
      >
        <Select
          loading={categories.isLoading}
          style={{ width: '100%' }}
          showSearch
          placeholder="Please select"
          getPopupContainer={(trigger) => trigger.parentNode}
          filterOption={(inputValue, option) => {
            return option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false;
          }}
        >
          {categories.data?.map((category) => {
            return (
              <Option key={category.id?.toString()} value={category.id || -1} title={category.name}>
                {category.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form>
  );
};
