import { useState } from 'react';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useCreateProjectSoftwareapp } from '../hooks/useCreateProjectSoftwareApp';
import { AddSoftwareAppForm } from './AddSoftwareAppForm';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import { SoftwareAppCreate } from '../../../api/engineering/domain/types';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { cloneDeep } from 'lodash';

export type AddSoftwareAppProps = {
  projectId: string;
};

const initialSwApp: SoftwareAppCreate = {
  name: '',
  categories: [],
  description: '',
  installationOptions: '',
  latestVersion: {
    version: SemanticVersion.initial().toString(),
    targets: [],
    releaseNotes: ''
  },
  documentationLink: ''
};

export const AddProjectSoftwareApp = (props: AddSoftwareAppProps) => {
  const permissions = usePermissions({ projectId: props.projectId });
  const [modalVisible, setModalVisible] = useState(false);

  const createSoftwareApp = useCreateProjectSoftwareapp();
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  return (
    <>
      {permissions.engineeringSvc$addProjectSoftwareApp && (
        <ExpandableMenuItem
          icon={<PlusOutlined />}
          loading={createSoftwareApp.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add Software App
        </ExpandableMenuItem>
      )}
      <ScrollDialog
        title="Add Software App"
        open={modalVisible}
        confirmLoading={createSoftwareApp.isLoading}
        destroyOnClose
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
        afterClose={() => form.resetFields()}
      >
        <AddSoftwareAppForm
          initial={cloneDeep(initialSwApp)}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              createSoftwareApp
                .mutateAsync([props.projectId, ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ScrollDialog>
    </>
  );
};
