import { CloseOutlined } from '@ant-design/icons';
import { Button, Layout, Result, Space, Typography } from 'antd';
import { useContext, useEffect, useMemo } from 'react';
import { ToolManagerArgs } from '../../../domain/toolmanager/toolmanagerArgs';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { PacTSIconLight } from '../../shared/components/icons/PacTSIconLight';
import { PacTSIcon } from '../../shared/components/icons/PacTSIcon';
import { ImpactsDownloadButton } from './ImpactsDownloadButton';
import { useSession } from '../../../contexts/session/hooks/useSession';
import { PacTSContext } from '../../../state/store';
import styled from 'styled-components';

const StyledResult = styled(Result)`
  margin-top: 10%;
`;

let hasAutoOpenedLink = false;
export const OpenInImpactsPage = () => {
  const [searchParam] = useSearchParameter('link');
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const impactsLink = useMemo(() => `pacts://${searchParam}`, [searchParam]);

  const openLinkButton = (
    <Button
      block
      type="primary"
      icon={<PacTSIconLight />}
      onClick={() => {
        window.location.replace(impactsLink);
      }}
      size="large"
    >
      Continue to ImPacTS
    </Button>
  );

  useEffect(() => {
    if (hasAutoOpenedLink) return;
    setTimeout(() => {
      hasAutoOpenedLink = true;
      window.location.replace(impactsLink);
    }, 200);
  }, [impactsLink]);

  const buttons = (
    <Space direction="vertical" size="large" style={{ marginTop: 50 }}>
      {openLinkButton}
      {session.state === 'loggedIn' && (
        <ImpactsDownloadButton block size="large" type="primary">
          Download ImPacTS
        </ImpactsDownloadButton>
      )}
      <Button
        icon={<CloseOutlined />}
        block
        size="large"
        onClick={() => {
          window.close();
        }}
      >
        Close Tab
      </Button>
    </Space>
  );

  const decoded: ToolManagerArgs | undefined = useMemo(() => {
    try {
      const json = atob(searchParam || '');
      const parsed = JSON.parse(json);
      return parsed;
    } catch (error) {
      return undefined;
    }
  }, [searchParam]);

  // Fallback value
  let description = 'Execute ImPacTS Action';
  let subtitle = '';
  const prefix = 'Open';
  const suffix = 'in ImPacTS';
  switch (decoded?.action) {
    case 'openComponent': {
      const name = decoded?.payload?.component?.name || '';
      const componentVersion = decoded?.payload?.component?.version || '';
      description = `${prefix} ${name} ${componentVersion} ${suffix}`;
      break;
    }
    case 'login': {
      description = 'Open in ImPacTS to continue.';
      subtitle = 'This login link is single-use and valid for 5 minutes.';
      break;
    }
    default:
      break;
  }

  const title = (
    <Space direction="vertical">
      <Typography.Title level={3}>{description}</Typography.Title>
    </Space>
  );

  return (
    <Layout.Content>
      <StyledResult
        icon={<PacTSIcon style={{ height: 60 }} />}
        title={title}
        subTitle={
          <>
            {subtitle && (
              <Typography.Title level={4} style={{ marginBottom: 16 }}>
                {subtitle}
              </Typography.Title>
            )}
            <div>This action requires ImPacTS to be installed on your computer.</div>
            <div>If already installed, you can proceed to open the link, if not, find the download button below.</div>
          </>
        }
        extra={buttons}
      />
    </Layout.Content>
  );
};
