import { Button, Tooltip } from 'antd';
import { SoftwareApp, SoftwareAppVersion, SoftwareComponent } from '../../../api';
import { useComponentVersion } from '../hooks/useComponentVersion';
import { DownloadOutlined } from '@ant-design/icons';
import { DownloadButton } from '../../../contexts/shared/components/Download/components/DownloadButton';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

const mapSoftwareComponentToDownloadableApp = (component: SoftwareComponent): { app: SoftwareApp; version: SoftwareAppVersion } => {
  // best-effort mapping of received component to app and version
  // reduces number of necessary backend calls
  // and makes component available in case of missing permissions
  const versionFromComponent: SoftwareAppVersion = {
    idSoftwareAppVersion: component.versionId,
    releaseNotes: '',
    state: 'consistent',
    targets: (component.targets ?? []).map((t) => ({
      // this is a hack to force-allow downloading even if we have not artifactory
      // download link in deployment plans
      downloadLink: 'https://artifactory.siemens-energy.com/artifactory/force-allow-download',
      target: { idTarget: t.id, name: t.name },
      sha256: t.sha256
    })),
    version: component.version
  };
  const appFromComponent: SoftwareApp = {
    name: component.name,
    categories: [],
    description: '',
    documentationLink: '',
    idSoftwareApp: component.id,
    installationOptions: '',
    latestVersion: versionFromComponent
  };

  return { app: appFromComponent, version: versionFromComponent };
};

export const SoftwareComponentDownloadGateway = (props: { projectId: number; component: SoftwareComponent }) => {
  const isFullComponent = (props.component.targets?.length ?? 0) > 0;

  const { query: queriedVersion, hasPermission: hasQueryPermission } = useComponentVersion(props.projectId, props.component, !isFullComponent);
  const permissions = usePermissions({
    projectId: props.projectId.toString(),
    toolId: props.component.id.toString(),
    softwareAppId: props.component.id.toString(),
    idToolVersion: props.component.versionId.toString(),
    idSoftwareAppVersion: props.component.versionId.toString()
  });

  const isProjectApp = props.component.scope === 'project' && props.component.type === 'app';
  const isCommonApp = props.component.scope === 'common' && props.component.type === 'app';
  const isCommonTool = props.component.scope === 'common' && props.component.type === 'tool';

  const hasPermissions =
    (isProjectApp &&
      permissions.engineeringSvc$getProjectSoftwareAppVersionTargetDownload &&
      permissions.engineeringSvc$headProjectSoftwareAppVersionTargetDownload) ||
    (isCommonApp &&
      permissions.engineeringSvc$getCommonSoftwareAppVersionTargetDownload &&
      permissions.engineeringSvc$headCommonSoftwareAppVersionTargetDownload) ||
    (isCommonTool && permissions.engineeringSvc$getToolVersionDownload && permissions.engineeringSvc$headToolVersionDownload);

  const hasData = (!isFullComponent && queriedVersion.data && queriedVersion.isSuccess) || isFullComponent;
  const canLoadData = hasQueryPermission && !isFullComponent;

  const { app: appFromComponent, version: versionFromComponent } = mapSoftwareComponentToDownloadableApp(props.component);

  const usedComponent = isFullComponent ? appFromComponent : queriedVersion.data?.component;
  const usedComponentVersion = isFullComponent ? versionFromComponent : queriedVersion.data?.version;

  let button: React.ReactNode;

  if (!hasPermissions) {
    return null;
  }

  if (!hasData && canLoadData) {
    button = <Button icon={<AnimatedLoadingIcon />} type="text" disabled />;
  } else if (hasData && !!usedComponent && !!usedComponentVersion) {
    button = (
      <DownloadButton
        icon={<DownloadOutlined />}
        artifact={usedComponent}
        version={usedComponentVersion}
        projectId={isProjectApp ? props.projectId : undefined}
        type="text"
      />
    );
  } else {
    button = <Button icon={<DownloadOutlined />} disabled type="text" />;
  }

  return <Tooltip title="Download">{button}</Tooltip>;
};
