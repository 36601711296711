import { useState, useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useCreateEngineeringTool } from '../hooks/useCreateEngineeringTool';
import { SemanticVersion } from '../../../domain';
import { useEngineeringToolCategories } from '../hooks/useEngineeringToolCategories';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { NameValidator } from '../../../domain/validation/nameValidator';
import { DescriptionValidator } from '../../../domain/validation/descriptionValidator';
import { ReleaseNotesValidator } from '../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../domain/validation/versionValidator';
import { DocumentationLinkValidator } from '../../../domain/validation/documentationLinkValidator';
import { Tool, ToolCreate } from '../../../api/engineering/domain/types';
import { BlobUploadFormItem } from '../../shared/components/BlobUploadFormItem';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { Sha256Validator } from '../../../domain/validation/sha256Validator';
import { ChecksumHowToFormLabel } from '../../../contexts/shared/components/ChecksumHowToFormLabel';
import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../../contexts/shared/components/Forms/ICSPortalComponentIdFormItem';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../constants/texts';

const { Option } = Select;

const initialEngineeringTool: ToolCreate = {
  name: '',
  category: [],
  description: '',
  installationOptions: '',
  latestVersion: {
    version: SemanticVersion.initial().toString(),
    releaseNotes: '',
    downloadLink: ''
  },
  deprecated: false,
  documentationLink: ''
};

export const AddEngineeringTool = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const categories = useEngineeringToolCategories();
  const createEngineeringTool = useCreateEngineeringTool();

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createEngineeringTool.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [createEngineeringTool.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        loading={createEngineeringTool.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        Add Engineering Tool
      </Button>
      <ScrollDialog
        title="Add Engineering Tool"
        destroyOnClose
        confirmLoading={createEngineeringTool.isLoading}
        open={modalVisible}
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ ...initialEngineeringTool }}
          form={form}
          onFinish={(value) => {
            const copy = { ...initialEngineeringTool, ...value } as Tool;
            const cats = categories.data?.filter((c) => (copy.category as any as number[]).find((cat) => cat === c.id));
            copy.category = cats || [];
            copy.latestVersion = { ...initialEngineeringTool.latestVersion, ...copy.latestVersion };
            createEngineeringTool.mutate([copy]);
          }}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            rules={[DescriptionValidator.rule()]}
            label="Description"
            field={['description']}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          <MarkdownFormItem
            optional
            rules={[DocumentationLinkValidator.rule()]}
            label="Documentation"
            field={['documentationLink']}
            shouldUpdate={(prevValues, currentValues) => prevValues.documentationLink !== currentValues.documentationLink}
          />
          <Form.Item label="Version String" name={['latestVersion', 'version']} rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            label="Release Notes"
            rules={[ReleaseNotesValidator.rule()]}
            field={['latestVersion', 'releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          <BlobUploadFormItem
            label="Download Link"
            field={['latestVersion', 'downloadLink']}
            rules={[{ required: true, message: 'Required' }]}
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.latestVersion.impacts$updateDeploymentLink !== currentValues.latestVersion.impacts$updateDeploymentLink
              );
            }}
          />
          <Form.Item
            label={<ChecksumHowToFormLabel />}
            name={['latestVersion', 'sha256']}
            required={false}
            rules={[Sha256Validator.rule(() => form.getFieldValue(['latestVersion', 'downloadLink']))]}
          >
            <Input />
          </Form.Item>
          
          <ICSPortalComponentIdFormItem
            name={['latestVersion', ICS_PORTAL_ID_NAME_KEY]}
          />

          <Form.Item label="Categories" name="category" rules={[{ required: true, message: 'Please select at least one category!', type: 'array' }]}>
            <Select
              mode="multiple"
              showSearch
              loading={categories.isLoading}
              style={{ width: '100%' }}
              placeholder="Please select"
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              {categories.data?.map((category) => {
                return (
                  <Option key={category.id} value={category.id || -1} title={category.name}>
                    {category.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </ScrollDialog>
    </>
  );
};
