import { useEffect } from 'react';
import { OpenComponentArgs, ToolManagerArgs, ToolManagerLink } from '../../../domain';
import { useProject } from '../../projects/hooks/useProject';
import { useProjectSoftwareapp } from '../../softwareApps/hooks/useProjectSoftwareapp';
import { useProjectSoftwareAppVersions } from '../../softwareApps/hooks/useProjectSoftwareappVersions';

export const SoftwareComponentOpenProjectAppInToolmanager = (props: { projectId: string; appId: string; versionId: string; onLoad: () => void }) => {
  const project = useProject(props.projectId);
  const softwareApp = useProjectSoftwareapp(props.projectId, props.appId);
  const softwareAppVersions = useProjectSoftwareAppVersions(props.projectId, props.appId);

  useEffect(() => {
    if (softwareApp.data && project.data && softwareAppVersions.data) {
      const version = softwareAppVersions.data.find((v: any) => v.idSoftwareAppVersion?.toString() === props.versionId);
      if (!version || version.targets.length < 1) {
        return;
      }

      const component: OpenComponentArgs = {
        component: {
          category: softwareApp.data.categories?.map((c) => c.name!).join(', ') || '',
          id: softwareApp.data.idSoftwareApp!.toString(),
          name: softwareApp.data.name,
          scope: 'project',
          targets: version.targets.map((t) => {
            return {
              targetId: t.target.idTarget,
              downloadLink: t.downloadLink,
              targetName: t.target.name,
              sha256: t.sha256
            };
          }),
          type: 'app',
          version: version.version,
          versionId: version.idSoftwareAppVersion!.toString(),
          context: { projectId: project.data.idProject!.toString(), projectName: project.data.name }
        }
      };
      const args: ToolManagerArgs = {
        action: 'openComponent',
        payload: component
      };
      ToolManagerLink.open(args);
      props.onLoad();
    }
  }, [props.versionId, softwareApp.data, softwareAppVersions.data, project.data, props]);

  return <></>;
};
