import { useQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { SoftwareAppVersion } from '../../../api';

export function useCommonSoftwareAppVersionVulnerabilities(appId: number, version: SoftwareAppVersion) {
  const hasComponentId = version.targets.some((t) => !!t.icsPortalComponentId);

  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ softwareAppId: appId.toString(), idSoftwareAppVersion: version.idSoftwareAppVersion.toString() });

  return useQuery(
    ['commonSoftwareAppVersionVulnerabilities', appId.toString(), version.idSoftwareAppVersion.toString()],
    () => {
      return backend.queryCommonAppVersionVulnerabilities(appId, version.idSoftwareAppVersion);
    },
    {
      enabled: hasComponentId && permissions.engineeringSvc$getCommonSoftwareAppVersionVulnerabilities
    }
  );
}
