import { useMemo, useRef } from 'react';
import { MessageManager } from '../../shared/messaging/messageManager';
import { usePactsFormationWebsocket } from '../../shared/messaging/pactsFormationWebsocket';

import { PactsFormationBackend } from '../service/pactsFormationBackend';
import {
  ArtifactDto,
  ArtifactResponse,
  GetProjectStatusRequestPayload,
  ImportProjectRequestPayload,
  MembershipResource,
  MembershipStatusResponse,
  ProjectDto,
  ProjectReadModelUpdatedEventPayload,
  ProjectsResponse,
  SyncProjectRolesResponsePayload
} from '../domain/types';
import { WebsocketOperations } from '../../shared/messaging/managedWebsocket';

class WebsocketPactsFormationBackend implements PactsFormationBackend {
  constructor(
    public readonly ws: MessageManager,
    private readonly ops: WebsocketOperations
  ) {}

  ping(args: { timeout?: number }): Promise<void> {
    return this.ws.send('ping', undefined, undefined, args?.timeout);
  }

  reconnect(): void {
    this.ops.reconnect();
  }

  queryResources(projectId: string): Promise<ProjectDto> {
    const request: GetProjectStatusRequestPayload = {
      projectId
    };
    return this.ws.send<ProjectDto>('projects/state/get', request);
  }

  syncProjectRoles(): Promise<SyncProjectRolesResponsePayload> {
    return this.ws.send<SyncProjectRolesResponsePayload>('project-roles/sync');
  }

  importProject(data: ImportProjectRequestPayload): Promise<void> {
    return this.ws.send<void>('projects/import', data);
  }

  onProjectResourceUpdate(handler: (data: ProjectReadModelUpdatedEventPayload) => any): void {
    this.ws.on('projects/update', handler);
  }

  offProjectResourceUpdate(handler: (data: ProjectReadModelUpdatedEventPayload) => any): void {
    this.ws.off('projects/update', handler);
  }

  getAllProjects(paginationToken: string | null): Promise<ProjectsResponse> {
    return this.ws.send(
      'projects/all/get',
      {
        paginationToken
      },
      undefined,
      undefined,
      false
    );
  }

  getAllMemberships(paginationToken: string | null): Promise<MembershipStatusResponse> {
    return this.ws.send(
      'memberships/all/get',
      {
        paginationToken
      },
      undefined,
      undefined,
      false
    );
  }

  getMembershipStatus(membershipId: string): Promise<MembershipResource> {
    return this.ws.send(
      'membership/status/get',
      {
        membershipId
      },
      undefined,
      undefined,
      false
    );
  }

  getAllArtifactVersions(paginationToken: string | null): Promise<ArtifactResponse> {
    return this.ws.send(
      'artifact/versions/all/get',
      {
        paginationToken
      },
      undefined,
      undefined,
      false
    );
  }

  getArtifactVersions(artifactId: string, versionId: string, artifactType: string): Promise<ArtifactDto> {
    return this.ws.send(
      'artifact/version/get',
      {
        artifactId,
        versionId,
        artifactType
      },
      undefined,
      undefined,
      true
    );
  }

  sendAction(action: string) {
    return this.ws.send(action);
  }
}

export const useWebsocketPactsFormationBackend = () => {
  const ws = usePactsFormationWebsocket();
  const activeBackend = useRef<WebsocketPactsFormationBackend>(new WebsocketPactsFormationBackend(ws[0], ws[2]));
  const backend = useMemo(() => {
    if (activeBackend.current.ws !== ws[0]) {
      console.warn('new backend instance');
      activeBackend.current = new WebsocketPactsFormationBackend(ws[0], ws[2]);
      return activeBackend.current;
    }
    return activeBackend.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws[0], ws[2]]);

  return useMemo(() => {
    return { backend, state: ws[1] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backend, ws[1]]);
};
