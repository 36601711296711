import { useState, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useCreateEngineeringToolVersion } from '../hooks/useCreateEngineeringToolVersion';
import { ReleaseNotesValidator } from '../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../domain/validation/sha256Validator';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../constants/texts';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../shared/components/Forms/ICSPortalComponentIdFormItem';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import { BlobUploadFormItem } from '../../shared/components/BlobUploadFormItem';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { ChecksumHowToFormLabel } from '../../../contexts/shared/components/ChecksumHowToFormLabel';

import type { Tool, ToolVersion, ToolVersionCreate } from '../../../api/engineering/domain/types';

const initialEngineeringToolVersion: ToolVersionCreate = {
  version: '',
  releaseNotes: '',
  downloadLink: ''
};

export type AddEngineeringToolVersionProps = {
  tool: Tool;
  detailView?: boolean;
};

export const AddEngineeringToolVersion = (props: AddEngineeringToolVersionProps) => {
  const permissions = usePermissions({ toolId: props.tool.id.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const createEngineeringTool = useCreateEngineeringToolVersion();

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createEngineeringTool.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [createEngineeringTool.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permissions.engineeringSvc$addToolVersion) return null;

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        loading={createEngineeringTool.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
        type="default"
        style={props.detailView ? { float: 'right' } : {}}
      >
        New Tool Version
      </Button>
      <ScrollDialog
        title="New Engineering Tool Version"
        destroyOnClose
        confirmLoading={createEngineeringTool.isLoading}
        open={modalVisible}
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{
            ...initialEngineeringToolVersion,
            version: SemanticVersion.fromUnsafeString(props.tool.latestVersion?.version)
              .increment()
              .toString()
          }}
          form={form}
          onFinish={(value) => {
            const copy = { ...initialEngineeringToolVersion, ...value } as ToolVersion;
            createEngineeringTool.mutate([props.tool, copy]);
          }}
        >
          <Form.Item label="Version" name="version" rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            label="Release Notes"
            field={['releaseNotes']}
            rules={[ReleaseNotesValidator.rule()]}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          <BlobUploadFormItem
            label="Download Link"
            field={['downloadLink']}
            rules={[{ required: true, message: 'Required' }]}
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.impacts$updateDeploymentLink !== currentValues.impacts$updateDeploymentLink;
            }}
          />
          <Form.Item
            label={<ChecksumHowToFormLabel />}
            name={['sha256']}
            required={false}
            rules={[Sha256Validator.rule(() => form.getFieldValue(['downloadLink']))]}
          >
            <Input />
          </Form.Item>
          <ICSPortalComponentIdFormItem name={[ICS_PORTAL_ID_NAME_KEY]} />
        </Form>
      </ScrollDialog>
    </>
  );
};
