import { List, Avatar, Skeleton, Statistic, Space, Divider, Row, Col, Switch, Card, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { Project } from '../../../api';
import { Comparator } from '../../../domain/extensions/comparison';
import { usePermissions } from '../../session/hooks/usePermissions';
import { PrimaryHeader } from '../../shared/components/PrimaryHeader';
import { useProjectMembers } from '../hooks/useProjectMembers';
import { ProjectAccessRequests } from './ProjectAccessRequests';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import { InfoTooltip } from '../../../contexts/shared/components/InfoTooltip';

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colorPrimary};
`;

const StatisticsTitleText = styled(Typography.Text)`
  width: 100%;
  display: inline-block;
  color: inherit;
`;

type ProjectMemberListProps = {
  project: Project;
};

export const ProjectMemberList = (props: ProjectMemberListProps) => {
  const projectMembers = useProjectMembers(props.project.idProject.toString());
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });

  const loading = projectMembers.isLoading;
  const success = projectMembers.isSuccess;

  const [showMembers, setShowMembers] = useState(false);

  const sortedProjectMembers = (projectMembers.data || []).sort((a, b) => {
    return Comparator.lexicographicalComparison(a.member.name.toLowerCase(), b.member.name.toLowerCase());
  });

  const availableRoles = useMemo(() => uniqBy(projectMembers.data?.map((pm) => pm.roles).flat() ?? [], (r) => r.idProjectRole), [projectMembers.data]);

  const StatisticTitle = (statTitleProps: { children: string; description: string }) => (
    <Row wrap={false} gutter={[8, 0]}>
      <Col>
        <StatisticsTitleText ellipsis={{ tooltip: statTitleProps.children }}>{statTitleProps.children}</StatisticsTitleText>
      </Col>
      <Col>
        <InfoTooltip text={statTitleProps.description} />
      </Col>
    </Row>
  );

  const statistics = useMemo(() => {
    if (success) {
      const stats = [
        <Statistic
          key="memberStatistic"
          title={<StatisticTitle description="All Members of the Project">Project Members</StatisticTitle>}
          value={projectMembers.data?.length || 0}
        />
      ];
      availableRoles.forEach((r) => {
        const membersWithRole = (projectMembers?.data || []).filter((m) => (m.roles || []).find((role) => r.idProjectRole === role.idProjectRole));
        stats.push(
          <Statistic
            key={`roleStatistic-${r.idProjectRole}`}
            title={<StatisticTitle description={r.description}>{r.name}</StatisticTitle>}
            value={membersWithRole.length}
          />
        );
      });
      return stats;
    }
    return [];
  }, [projectMembers.data, availableRoles, success]);

  return (
    <Card
      title={<PrimaryHeader>Project Members</PrimaryHeader>}
      extra={[
        <Space key="showMembers" style={{ float: 'right' }}>
          Show Members
          <Switch checked={showMembers} onChange={setShowMembers} />
        </Space>
      ]}
    >
      {loading && <Skeleton active />}
      {success && (
        <div>
          {permissions.engineeringSvc$addProjectMember && (
            <>
              <ProjectAccessRequests project={props.project} />
              <Divider />
            </>
          )}
          <Row justify="start" wrap={true} gutter={[16, 16]}>
            {statistics.map((s, idx) => (
              <Col flex="1" key={`stat-${idx}`}>
                {s}
              </Col>
            ))}
          </Row>
          {showMembers && (
            <>
              <Divider />
              <List
                dataSource={sortedProjectMembers}
                grid={{ gutter: 16, column: 4 }}
                loading={projectMembers.isLoading}
                renderItem={(pm) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<StyledAvatar>{pm.member.name[0]}</StyledAvatar>}
                      title={pm.member.name}
                      description={pm.roles
                        ?.map((r) => r.name)
                        .sort()
                        .join(', ')}
                    />
                  </List.Item>
                )}
              />
            </>
          )}
        </div>
      )}
    </Card>
  );
};
