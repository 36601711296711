import { useState, useEffect, useRef, useMemo, ComponentProps } from 'react';
import { Col, Row, Select } from 'antd';
import { uniqBy } from 'lodash';

import { LatestTag } from '../../shared/components/LatestTag';
import { BundleTag } from '../../shared/components/BundleTag';
import { Comparator } from '../../../domain/extensions/comparison';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import { SyncingTag } from '../../shared/components/SyncingTag';
import { CommonSoftwareAppVersionVulnerabilityButton } from './CommonSoftwareAppVersionVulnerabilityButton';

import { useCommonSoftwareAppVersions } from '../hooks/useCommonSoftwareappVersions';
import { useCommonSoftwareapp } from '../hooks/useCommonSoftwareapp';

import type { SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { FullWidthSelect } from '../../../contexts/shared/base/Components.styled';

const { Option } = Select;

export const CommonSoftwareAppVersionDropdown = (props: {
  softwareAppId: string;
  selectedVersion?: SoftwareAppVersion;
  initiallySelectedVersion?: SoftwareAppVersion;
  onSelected: (version: SoftwareAppVersion) => any;
  getVulnerabilityDrawerContainer?: ComponentProps<typeof CommonSoftwareAppVersionVulnerabilityButton>['getVulnerabilityDrawerContainer'];
}) => {
  const [selectedVersion, setSelectedVersion] = useState('');

  const softwareApp = useCommonSoftwareapp(props.softwareAppId);
  const softwareAppVersions = useCommonSoftwareAppVersions(props.softwareAppId);

  // include initially selected version even without access to software app versions list
  const data = useMemo(() => {
    return uniqBy(
      [...(softwareAppVersions.data ?? []), props.initiallySelectedVersion].filter(Boolean),
      (d) => d!.idSoftwareAppVersion
    ) as SoftwareAppVersion[];
  }, [softwareAppVersions.data, props.initiallySelectedVersion]);

  const sortedVersions = useMemo(() => {
    return data.sort((a, b) => {
      if (a.idSoftwareAppVersion === softwareApp.data?.latestVersion?.idSoftwareAppVersion) return -1;
      if (b.idSoftwareAppVersion === softwareApp.data?.latestVersion?.idSoftwareAppVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    });
  }, [data, softwareApp.data]);

  const onChange = (version: string) => {
    if (version !== selectedVersion) {
      setSelectedVersion(version);

      if (version === softwareApp.data?.latestVersion?.idSoftwareAppVersion?.toString()) {
        props.onSelected(softwareApp.data?.latestVersion);
      } else if (sortedVersions) {
        const existingSelectedVersion = sortedVersions.find((swa) => swa.idSoftwareAppVersion?.toString() === version);

        if (existingSelectedVersion) props.onSelected(existingSelectedVersion);
      }
    }
  };

  const lastInitial = useRef('');
  useEffect(() => {
    if (props.selectedVersion && props.selectedVersion.idSoftwareAppVersion.toString() !== lastInitial.current) {
      lastInitial.current = props.selectedVersion.idSoftwareAppVersion.toString();
      setSelectedVersion(props.selectedVersion.idSoftwareAppVersion.toString());
    }
  }, [props]);

  const hasSelectedVersion = sortedVersions.some((s) => s.idSoftwareAppVersion.toString() === selectedVersion);
  const isLoading = (softwareAppVersions.isLoading || softwareApp.isLoading) && data.length < 1;

  const appVersion = useMemo(
    () => softwareAppVersions.data?.find((v) => v.idSoftwareAppVersion.toString() === selectedVersion),
    [softwareAppVersions.data, selectedVersion]
  );

  const showVulnerabilityButton = softwareApp.isSuccess && softwareAppVersions.isSuccess && !!appVersion;

  return (
    <Row wrap={false} gutter={[8, 0]}>
      <Col flex="auto">
        <FullWidthSelect
          className={`common-app-version-selector-${props.softwareAppId}`}
          value={hasSelectedVersion ? selectedVersion : ''}
          loading={isLoading}
          onSelect={(val: any) => onChange(val.toString())}
          // delete overscrollBehaviour: contain for scroll-chaining
          dropdownStyle={{ overscrollBehavior: 'contain' }}
          showSearch
          optionFilterProp="label"
          filterOption={(input, option) => (option?.title || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {sortedVersions &&
            sortedVersions.map((swa) => {
              const targets = swa.targets
                .map((t) => t.target.name)
                .sort(Comparator.lexicographicalComparison)
                .join(', ');
              const title = `${swa.version} (${targets})`;

              return (
                <Option
                  id={`common-app-${props.softwareAppId}-${swa.idSoftwareAppVersion}`}
                  title={title}
                  key={swa.idSoftwareAppVersion?.toString()}
                  value={swa.idSoftwareAppVersion?.toString() || 'unknown'}
                >
                  <span>
                    {swa.idSoftwareAppVersion === softwareApp.data?.latestVersion?.idSoftwareAppVersion ? <LatestTag /> : null}
                    {swa.idSoftwareAppVersion === props.initiallySelectedVersion?.idSoftwareAppVersion ? <BundleTag /> : null}
                    {swa.state && swa.state !== 'consistent' ? <SyncingTag /> : null}
                    {title}
                  </span>
                </Option>
              );
            })}
        </FullWidthSelect>
      </Col>
      <Col flex="40px">
        {showVulnerabilityButton && (
          <CommonSoftwareAppVersionVulnerabilityButton
            app={softwareApp.data}
            version={appVersion}
            getVulnerabilityDrawerContainer={props.getVulnerabilityDrawerContainer}
          />
        )}
      </Col>
    </Row>
  );
};
