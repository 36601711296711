import { useEffect, useRef } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useAllNotifications } from '../../notifications/hooks/useNotifications';
import { useInvalidateQueries } from './useInvalidateQueries';
import { useTokenIssueDate } from './useTokenIssueDate';
import { useTokenRefresh } from './useTokenRefresh';

export const useNotificationTriggerRefresh = singletonHook<void>(undefined, () => {
  const notifications = useAllNotifications();
  const tokenIaT = useTokenIssueDate();
  const lastTriggerNotificationTime = useRef(0);
  const invalidateAll = useInvalidateQueries();
  const tokenRefresh = useTokenRefresh();

  useEffect(() => {
    if (!tokenIaT) {
      return;
    }

    const triggerNotifications = (notifications.data || [])
      .filter((n) => n.meta?.trigger?.tokenRefresh)
      .sort((a, b) => b.received.getTime() - a.received.getTime());

    if (triggerNotifications.length < 1) return;

    const shouldTrigger = triggerNotifications[0].received > tokenIaT;
    if (!shouldTrigger) {
      return;
    }
    const hasTriggered = lastTriggerNotificationTime.current === triggerNotifications[0].received.getTime();
    if (hasTriggered) {
      return;
    }

    console.warn('refetching due to trigger notification', { tokenIaT, received: triggerNotifications[0].received });
    lastTriggerNotificationTime.current = triggerNotifications[0].received.getTime();

    invalidateAll();

    // TODO: remove reconnect once all backends implement token without permissions
    // Reconnecting the notification websocket might be required in the future
    tokenRefresh().catch(() => {
      /* */
    });
  }, [notifications.data, tokenIaT, invalidateAll, tokenRefresh]);
});
