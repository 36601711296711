import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { SubscriptionPopup } from '../../../contexts/notifications/components/SubscriptionPopup';
import { ExpandableMenu, ExpandableMenuItem } from '../../../contexts/shared/components';
import { DownloadContextMenuButton } from '../../../contexts/shared/components/Download/components/DownloadContextMenuButton';
import { toolHash } from '../utils';
import { EngineeringToolsOpenInToolManagerButton } from './EngineeringToolsOpenInToolManagerButton';
import { Project, Tool } from '../../../api';
import { useInAppNavigate } from '../../../contexts/navigation/hooks';
import { EngineeringToolSelection } from '../../../domain';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';

export const EngineeringToolActionsMenu = (props: { tool: Tool; project?: Project; selection?: EngineeringToolSelection }) => {
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    toolId: props.tool.id.toString(),
    idToolVersion: props.selection?.version.idToolVersion.toString()
  });
  const navigate = useInAppNavigate();
  const metrics = useSubmitMetric();

  const canGetVersions = permissions.engineeringSvc$getToolVersions || permissions.engineeringSvc$getToolVersion$specific().length > 0;

  return (
    <Space>
      <SubscriptionPopup type="text" title={props.tool.name} topics={[{ key: `tools/${props.tool.id}/**`, title: props.tool.name }]} />

      <ExpandableMenu textType id={`engineering-tool-actions-${props.tool.id}`}>
        {permissions.webui$showComponentDetails && (
          <ExpandableMenuItem
            icon={<InfoCircleOutlined />}
            onClick={() => {
              metrics
                .submitClick({
                  operationId: MetricClickIds.componentDetails
                })
                .withToolContext(props.tool);
              if (props.project) {
                navigate(`/tools/${props.tool.id}/details?projectId=${props.project.idProject}`, {}, toolHash(props.tool));
              } else {
                navigate(`/tools/${props.tool.id}/details`, {}, toolHash(props.tool));
              }
            }}
          >
            Details
          </ExpandableMenuItem>
        )}
        {canGetVersions && (
          <ExpandableMenuItem
            icon={<OrderedListOutlined />}
            onClick={() => {
              metrics
                .submitClick({
                  operationId: MetricClickIds.componentVersions
                })
                .withToolContext(props.tool);
              if (props.project) {
                navigate(`/tools/${props.tool.id}/versions?projectId=${props.project.idProject}`, {}, toolHash(props.tool));
              } else {
                navigate(`/tools/${props.tool.id}/versions`, {}, toolHash(props.tool));
              }
            }}
          >
            Versions
          </ExpandableMenuItem>
        )}
        {props.selection && <DownloadContextMenuButton artifact={props.selection.engineeringTool} version={props.selection.version} />}
        {props.selection && <EngineeringToolsOpenInToolManagerButton selection={props.selection} project={props.project} />}
      </ExpandableMenu>
    </Space>
  );
};
